@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;400;600;700&family=Raleway&display=swap');
/* font-family: 'Josefin Sans', sans-serif;
font-family: 'Raleway', sans-serif; */

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Josefin Sans', sans-serif;
}

.gallery {
    /* border: 1px solid black; */
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-width: 33%;
    -moz-column-width: 33%;
    column-width: 33%;
    padding: 0 12px;
}

.gallery .pics{
    margin-bottom: 12px;
}

@media (max-width: 991px){
    .gallery{
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;
    }
}

@media (max-width: 580px){
    .gallery{
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1;
        -webkit-column-width: 100%;
        -moz-column-width: 100%;
        column-width: 100%;
    }
}

